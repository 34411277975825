import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import MyApps from "../mocks/my-apps.json";
import { Subscription, SubscriptionRaw } from "../utils/models";
import Config from "../config/auth.json";
import Server from "../config/server.json";
import { useAuth } from "../hooks/";
import { AdditionalConfig } from "../utils/consts";
import { serializeSubscription } from "../utils/serializers";

const fetch = async (): Promise<SubscriptionRaw[]> => {
  let responseData: any[] = [];
  if (Server.useMocks) {
    return MyApps;
  }
  const storedData = sessionStorage.getItem("subscriptions");
  if (storedData) {
    const parsedData = JSON.parse(storedData as string);
    return parsedData || null;
  } else {
    try {
      const response = await axios.get(Config.endpoint + Server.subscriptions, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("okta-token-storage") || "{}")?.accessToken?.accessToken,
        },
      });
      if (response.data === null) {
        sessionStorage.setItem("subscriptions", JSON.stringify(responseData));
      } else {
        responseData = response.data;
        sessionStorage.setItem("subscriptions", JSON.stringify(response.data));
      }
      return responseData;
    } catch (error) {
      console.error("Error fetching Data: ", error);
      return [];
    }
  }
};
export const useGetMySubscriptions = (): UseQueryResult<Subscription[]> => {
  const idToken = JSON.parse(window.localStorage.getItem("okta-token-storage") || "{}")?.accessToken?.accessToken;
  return useQuery(["subscriptions"], fetch, {
    enabled: !!idToken,
    select: (data) => data.map(serializeSubscription),
    ...AdditionalConfig,
  });
};
