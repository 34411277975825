import { useQuery, UseQueryResult } from "react-query";
import PlatformsList from "../mocks/platformsList.json";
import { Platform, PlatformRaw } from "../utils/models";
import Server from "../config/server.json";
import { AdditionalConfig } from "../utils/consts";
import { serializePlatform } from "../utils/serializers";
import axios from "axios";
import Config from "../config/auth.json";

const fetch = async (): Promise<PlatformRaw[]> => {
  if (Server.useMocks) {
    return PlatformsList;
  }
  const storedData = sessionStorage.getItem("allapps");
  if (storedData) {
    const parsedData = JSON.parse(storedData as string);
    return parsedData || null;
  } else {
    try {
      const response = await axios.get(Config.endpoint + Server.apps);
      sessionStorage.setItem("allapps", JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      console.error("Error fetching Data: ", error);
      return [];
    }
  }
};

export const useGetPlatforms = (): UseQueryResult<Platform[]> => {
  return useQuery(["platforms"], fetch, {
    select: (data) => data.map(serializePlatform).sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10)),
    ...AdditionalConfig,
  });
};
